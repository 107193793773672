import React, { forwardRef, HTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './container.module.scss';

type ContainerProps = HTMLAttributes<HTMLDivElement> & {
    hasVerticalGutter?: boolean;
    hasGutter?: boolean;
};

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
    ({ hasVerticalGutter, hasGutter, className, children, ...rest }, ref) => {
        return (
            <div
                ref={ref}
                className={clsx(
                    styles.container,
                    {
                        [styles.verticalGutter]: hasVerticalGutter,
                        [styles.gutter]: hasGutter,
                    },
                    className,
                )}
                {...rest}
            >
                {children}
            </div>
        );
    },
);

Container.displayName = 'Container';
